.publiNoc {
  position: relative;
  height: 50vw;
  width: 100%;
  margin-top: 20px;
  background: url("../img/CartelNocturno.png");
  background-size: cover;
  top: -100px;
  background-position: center top;
  margin-bottom: -140px;
}

.posBotNoc {
  position: relative;
  width: auto;
  height: auto;
}
.positionModalNoc {
  position: relative;
  top: 30vw;
  left: 0vw;
}
.fa-chevron-down {
  margin-left: 10px;
}
.fa-chevron-up {
  margin-left: 10px;
}
.nocnoc {
  margin-top: 63px;
}
.bachiller {
  font-size: 20px;
}
@media screen and (max-width: 1199px) {
  .nocnoc {
    margin-top: 23px;
  }
}

@media screen and (max-width: 555px) {
  .publiNoc {
    height: 120vw;
    background: url("../img/CartelNocturno.png");
    background-size: cover;
    top: -100px;
    background-position: center top;
  }
  .positionModalNoc {
    top: 60vw;
  }
  .nocnoc {
    margin-top: 0px;
  }
  .bachiller {
    font-size: 14px;
  }
}
