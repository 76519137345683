.encabezadoPrimario {
  background: url("../img/CartelPrimario.png");
  position: relative;
  height: 50vw;
  width: 100%;
  margin-top: 20px;
  background-size: cover;
  top: -100px;
  background-position: bottom;
  background-color: #103a87;
  margin-bottom: -140px;
}
.positionModalPri {
  position: relative;
  top: 32vw;
}
@media screen and (max-width: 550px) {
  .encabezadoPrimario {
    height: 140vw;
    top: -120px;
  }
  .positionModalPri {
    top: 72vw;
  }
}
