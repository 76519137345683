.col-year {
  padding-left: 0px !important;
}

.div-year {
  clip-path: polygon(0 0, 87% 0, 100% 100%, 0% 100%);
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left !important;
  margin-top: 4px;
  margin-bottom: 4px;
}
#uno,
#dos,
#tres,
#cuatro {
  /* padding-top: 57px;
  padding-bottom: 56px; */
}
#cinco {
  /* padding-top: 37px;
  padding-bottom: 37px; */
}
#unoydos,
#cincoyseis {
  /* padding-top: 37px;
  padding-bottom: 37px; */
}

.sec-ajuste {
  padding-top: 108px;
  padding-bottom: 108px;
}
.cont-btn-y {
  background: brown;
  width: 100%;
  /* padding-top: 7px;
  padding-bottom: 7px; */
  /* margin: 4px 0; */
}

.btn-y {
  position: relative;
  text-align: left !important;
  text-decoration: none !important;
  color: black;
  /* background: #e02bff; */
  padding-top: 7px;
  padding-bottom: 7px;
  /* padding-left: 8vw;
  padding-right: 8vw; */
  margin: 4px 0;
  display: inline-block;
  /* transition: 0.5s ease-out;
  transform-origin: left;
  transform: scaleX(1);
	background-size: 200%; */
  transition: 0.5s ease-out;
  opacity: 0.8;
}

.btn-y::before {
  content: "";
  position: absolute;
  width: 1000px;
  height: 100%;
  left: -860px;
  margin-top: -8px !important;
  text-align: left !important;
  background: #e02bff;
  opacity: 0;
  transform: translateX(-800px);
  transition: transform 0.5s ease-in-out;
  /* transition-timing-function: ease; */
}
.btn-y:hover::before {
  transform: translateX(5%);
  opacity: 1;
  overflow: hidden !important;
  z-index: -1;
}
.btn-y:hover {
  text-decoration: none !important;
  color: white;
  font-weight: bold;
  transition-delay: 0.1s;
  opacity: 1;
  cursor: pointer;
}
.close::before {
  border: none;
}

/* /////////////////////////// */
.apartado-materias {
  position: relative;
  margin-top: 0px;
  /* border: 2px solid red; */
}

.tit {
  position: absolute;
  right: 0px;
  /* left: 100px; */
  top: -44px;
  clip-path: polygon(13% 0, 100% 0, 100% 100%, 0% 100%);
  padding-left: 80px;
  background: black;
  background-size: 100%;
  background-repeat: repeat;
  color: white;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 0px !important;
}

.col-materias {
  position: relative;
  /* padding-right: 0px !important; */
  margin-top: 0px;
  margin-bottom: 0px;
}

.materias h2 {
  /* margin-top: -11px; */
}

.materias {
  position: absolute;
  /* left: 00px;
  right: 0px; */
  padding-top: 3px;
  padding-right: 40px;
  /* background: linear-gradient(
    90deg,
    rgba(246, 243, 255, 1) 0%,
    rgba(224, 43, 255, 0.5186449579831933) 100%
  ); */
  /* text-align: center; */
  z-index: -2;
}

.materias li {
  color: black;
  list-style: none;
  /* margin-top: 15px; */
}
@media screen and (max-width: 2000px) {
  .div-year-nocturno {
    width: 90%;
  }
}

@media screen and (max-width: 1192px) {
  .materias h2 {
    /* font-size: 22px; */
    /* padding-top: 11px; */
  }

  .div-year {
    width: 90%;
  }
  .div-year-nocturno {
    width: 84%;
  }
}

@media screen and (max-width: 767px) {
  .div-year {
    width: 102%;
  }
  .materias {
    position: relative;
    /* margin-top: -80px; */
    /* margin-left: 0px !important; */
    /* left: 0; */
  }
  .col-materias {
    padding-left: 0px;
  }
  .materias li:last-child {
    padding-bottom: 0px;
  }
  .tit {
    font-size: 27px;
  }
}
