/* Estilos para el footer */
.middle {
  border-radius: 0 95px 0 0;
  position: relative;
  height: auto;
  top: auto;
  width: 100%;
  text-align: center;
  color: white;
  background: black;
  margin-top: 40px;
  padding-top: 80px;
}
.boton {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #f1f1f1;
  margin: 10px;
  margin-top: 50px;
  border-radius: 30%;
  box-shadow: 0 5px 15px -5px #00000070;
  color: #103a87;
  overflow: hidden;
  position: relative;
}
.boton i {
  line-height: 50px;
  font-size: 26px;
  transition: 0.2s linear;
}
.boton:hover i {
  transform: scale(1.3);
  color: #f1f1f1;
}
.boton::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  background: #103a87;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.boton:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}
@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }
  50% {
    left: 10%;
    top: -30%;
  }
  100% {
    top: -10%;
    left: -10%;
  }
}
.middle .contacto {
  font-family: "B612", sans-serif;
  line-height: 1.4;
  text-align: left;
  font-size: 18px;
  position: relative;
  margin-left: 20px;
}
.middle .derecho {
  margin: 0px;
  padding-bottom: 30px;
}
.row .col-md-6 .contacto {
  height: 150px;
  /* padding-left: 40px; */
}
.row {
  width: 100%;
}

.logo-william-footer {
  width: 90px;
  height: 90px;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1155px) {
  .middle .contacto {
    font-size: 15px;
  }
}

@media screen and (max-width: 380px) {
  .contacto {
    padding-left: 10px !important;
  }
  .icons {
    padding-left: 20px !important;
  }
  .middle .derecho {
    padding-left: 20px !important;
  }
}

/* Boton personal */
.bt-personal a {
  padding: 0 130px;
}
.bt-personal {
  text-align: center;
}
.bt-3 {
  /* font-weight: bold; */
  border: 1px solid;
  border-radius: 10px;
  display: inline-block;
  font-size: 16px;
  line-height: 40px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  color: white;
}
.bt-3:hover {
  color: gray;
  text-decoration: none;
}

@media screen and (max-width: 377px) {
  .bt-personal a {
    padding: 0 100px;
  }
}
