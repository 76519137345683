.publiSec {
  position: relative;
  height: 50vw;
  width: 100%;
  margin-top: 20px;
  background: url("../img/CartelSecundario.png");
  background-size: cover;
  top: -100px;
  background-position: center;
  background-color: #103a87;
  margin-bottom: -140px;
}

.positionModalSec {
  position: relative;
  top: 32vw;
  /* left: 24%; */
}
.posTituloSec {
  position: relative;
}
.posTituloSec h1 {
  position: absolute;
  top: 16vw;
  left: 20vw;
  /* border: 2px solid brown; */
  font-family: piola;
  color: white;
  font-size: 140px;
}
@media screen and (max-width: 1750px) {
  .posTituloSec h1 {
    top: 18vw;
    font-size: 100px;
  }
}
@media screen and (max-width: 1400px) {
  .posTituloSec h1 {
    top: 19.2vw;
    font-size: 70px;
  }
}
@media screen and (max-width: 1000px) {
  .posTituloSec h1 {
    top: 22.2vw;
    font-size: 50px;
  }
}
@media screen and (max-width: 850px) {
  .posTituloSec h1 {
    top: 22.2vw;
    font-size: 40px;
  }
}
@media screen and (max-width: 650px) {
  .posTituloSec h1 {
    top: 22.2vw;
    font-size: 36px;
  }
}
@media screen and (max-width: 550px) {
  .publiSec {
    height: 90vw;
  }
  .posTituloSec h1 {
    top: 36.2vw;
    /* font-size: 30px; */
  }
  .positionModalSec {
    top: 50vw;
  }
}
@media screen and (max-width: 400px) {
  .posTituloSec h1 {
    top: 38.2vw;
    /* font-size: 30px; */
  }
  .positionModalSec {
    top: 56vw;
  }
  .salitas-subtitulo-noc {
    margin-top: 0px;
    font-size: 40px;
  }
}
