.titulo-nivel {
  font-family: "B612", sans-serif;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.titulo-nivel h3 {
  font-size: 100px;
  color: #1e2337;
}
section div h2 {
  font-size: 30px;
}
.titulo-nivel hr {
  /* color: red; */
  border-bottom: 5px solid #b60a0a;
  /* border-radius: 100%; */
  width: 50px;
}
.section-titulo-nivel p {
  font-family: "B612", sans-serif;
  font-size: 20px;
}
.titulo-nivel h1 {
  font-size: 25px;
  color: #1e2337;
  font-weight: bold;
}

.text-parrafo {
  margin-top: -10px !important;
  text-align: center;
  margin-bottom: 50px;
  /* margin: 80px 80px; */
}

@media screen and (max-width: 550px) {
  .text-parrafo {
    text-align: left;
    justify-content: left;
    margin-left: 20px;
    margin-right: 20px;
  }
  .titulo-nivel h3 {
    font-size: 60px;
  }
}
