/* Estilos para las tarjetas */
.linea {
  width: 50px;
  border-bottom: 6px solid #b60a0a;
  /* border-radius: 10px; */
  margin-bottom: 30px;
}

.contenedorTarjeta {
  position: relative;
  text-align: center;
  width: auto;
  height: auto;
}
.content-externo {
  border-radius: 0 0 0 95px;
  /* background-color: black; */
  margin-top: 0px !important;
}
.tarjeta {
  align-items: center;
  justify-items: center;
}
.contenedorTarjeta .tarjeta {
  position: relative;
  width: 300px;
  height: 420px;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  /* transition: 0.3s ease-in-out; */
  align-items: center;
}
.contenedorTarjeta .tarjeta .fotoCard {
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 300px;
  height: 420px;
  transition: all 0.3s ease-out !important;
}
.contenedorTarjeta .tarjeta:hover .fotoCard {
  background-color: rgba(0, 0, 0, 0.8);
  filter: brightness(0.2);
  transition: all 0.3s ease-out !important;
}
.fotoUno {
  background-image: url(../img/NivelInicial.jpg);
}
.fotoDos {
  background-image: url(../img/NivelPrimario.jpg);
}
.fotoTres {
  background-image: url(../img/NivelSecundario.jpg);
}
.fotoCuatro {
  background-image: url(../img/NivelNocturno.jpg);
}

/* Boton ver mas */

/* ///////////////////////7 */
.contenedorTarjeta .tarjeta:hover .botonCard {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-out !important;
}
.botonCard {
  visibility: hidden;
  z-index: 100;
  text-align: center;
  place-items: center;
}
.verMasBTN {
  position: relative;
  color: black;
  font-family: piola;
  background-color: white;
  border-radius: 50px;
  font-size: 20px;
  text-decoration: none;
  padding: 0.5rem 1rem;
  top: 220px;
  transition: 0.2s ease-in;
}

.inscribirmeBTN {
  font-family: piola;
  position: relative;
  color: white;
  /* border: 2px solid; */
  border-radius: 50px !important;
  font-size: 24px;
  text-decoration: none;
  padding: 2px 35px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  transition: 0.2s ease-in;
  background-color: #103a87;
}

.verMasBTN:hover {
  background-color: rgba(17, 16, 16, 0.8);
  color: white;
  border-radius: 50px !important;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease-out !important;
}
.inscribirmeBTN:hover {
  color: rgb(255, 255, 255) !important;
  background-color: #b60a0a;
  text-decoration: none;
  text-decoration: none;
  cursor: pointer;
}
/* ////// */

@media (min-width: 768px) {
  .mr-medidas-5,
  .mx-md-5 .tarjeta {
    margin-right: 0rem !important;
    height: 480px;
  }
}
.mr-medidas-5 {
  padding: 0px !important;
}
.contenedorTarjeta .row {
  margin: 0px;
  justify-content: center;
}
@media (min-width: 1250px) {
  .mr-medidas-5 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
/* @media screen and (max-width: 600px) {
  .info-card-text {
    padding: 30px 0px !important;
  }
} */
