.encabezadoInicial {
  background: url("../img/Cartelinicial.png");
  position: relative;
  height: 50vw;
  width: 100%;
  margin-top: 20px;
  background-size: cover;
  background-color: #103a87;
  top: -100px;
  background-position: center;
  margin-bottom: -140px;
}
.posicionEncabezadoInicial {
  position: relative;
}
.positionModalIni {
  position: relative;
  top: 32vw;
  left: 0vw;
}
.arrowBTN {
  position: absolute;
  color: rgb(255, 255, 255);
  bottom: 7px;
  left: 49%;
  z-index: -10;
}

@media screen and (max-width: 555px) {
  .encabezadoInicial {
    height: 170vw;
    background: url("../img/Cartelinicial.png");
    background-size: cover;
    top: -130px;
    background-position: center;
  }
  .positionModalIni {
    top: 103vw;
  }
}
.iconInstagram{
  color: black;
}