.fondoInicio {
  content: "";
  position: absolute;
  /* margin: -6px; */
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  border-radius: 0 0 50% 50%/0 0 50% 50%;
  transform: scaleX(1.5);
  background: url("../img/montaña2.jpg");
  /* background: black; */
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.8);
  filter: brightness(0.6);
}

.NavbarItems {
  position: absolute;
  /* background: linear-gradient(
    90deg,
    rgba(118, 190, 255, 1) 0%,
    rgba(3, 95, 191, 1) 100%
  ); */
  /* height: 80px; */
  display: flex;
  justify-content: auto;
  align-items: center;
  font-size: 1rem;
  width: 100%;
  z-index: 1000;
}

.text-logo {
  font-family: "B612", sans-serif;
  color: #fff !important;
  text-decoration: none !important;
}

.navbar-logo {
  text-decoration: none !important;
  justify-self: start;
  margin-left: 20px;
  margin-top: 24px;
  cursor: pointer;
  font-size: 1.3rem;
}
.logo-william {
  width: 60px;
  height: 60px;
}

.fa-react {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.content-items {
  margin-left: auto;
  /* margin-right: auto; */
}

.nav-menu {
  padding-top: 17px;
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 2px;
  list-style: none;
  text-decoration: none;
  text-align: center;
  /* width: 40vw; */
  justify-content: right;
  margin-right: 0rem;
  margin-top: 24px;
}
.corriente {
  background-color: rgba(245, 45, 45, 0.664) !important ;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 10px;
  /* padding-right: 40px !important; */
}
.corriente:hover {
  background-color: rgba(117, 11, 11, 0.582) !important ;
}
.logoauto {
  position: absolute;
  background-size: cover !important;
  right: 20px;
  top: 42px;
  height: 26px;
  width: 24px;
  background: url("../img/LOGOAUTOGESTION.png");
}

.nav-links {
  border-radius: 50px;
  font-family: piola;
  color: white;
  background: none;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.nav-none {
  display: none;
  margin: 0px;
  padding: 0px;
}

.nav-links:hover {
  background-color: rgba(17, 16, 16, 0.8);
  color: white;
  border-radius: 50px !important;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease-out !important;
}
.active{
  color: black;
  background-color: white;
}

.default {
  color: white;
  /* background-color: white; */
}
.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.drop {
  width: auto;
}

.div {
  width: 100%;
  height: 100%;
  background: #000;
}
.corriente {
  background-color: rgba(117, 11, 11, 0.582) !important ;
}
.corriente:hover {
  background: rgba(214, 68, 68, 0.664) !important ;
  color: white !important;
}
@media screen and (max-width: 956px) {
  .drop {
    width: auto;
  }
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 690px;
    position: absolute;
    /* top: 80px; */
    top: -25px;
    padding-top: 90px;
    padding-right: 40px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background-color: rgba(17, 16, 16, 0.8);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1000;
  }
  .nav-links {
    text-align: center;
    padding: 1rem;
    margin-right: 30px;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #fff;
    color: black;
    font-weight: bold;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    margin-top: 24px;
    top: -30px;
    left: -50px;
    transform: translate(25%, 50%);
    /* overflow: visible; */
    z-index: 10000;
  }
  .menu-icon {
    display: block;
    position: absolute;
    margin-top: 12px;
    top: -6px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 100000;
    overflow: visible;
  }
  .fa-times {
    color: #fff;
    font-size: 2rem;
    z-index: 100000;
    overflow: visible;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: auto auto;
    border-radius: 4px;
    width: 80%;
    background: #4ad9e4;
    color: #fff;
    font-size: 1.5rem;
  }
  .nav-links-mobile:hover {
    background: #fff;
    color: #6568f4;
    transition: 250ms;
    text-decoration: none;
  }

  .NavbarItems Button {
    display: none !important;
  }

  .logoauto {
    height: 46px;
    width: 44px;
    right: 30%;
    top: 81% !important;
  }
}

@media screen and (max-width: 460px) {
  .text-logo {
    display: none;
  }
  .logo-william {
    position: absolute;
    top: 31px;
    left: 80px;
  }
  .logoauto {
    right: 20%;
    top: 81% !important;
  }
}
