/* BOTON HOVER - EJEMPLO PAG SECUNDARIO */
.btn-h {
  letter-spacing: 0.1em;
  cursor: pointer;
  /* display:contents; */
  font-size: 14px;
  line-height: 70px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  /* width: 100%; */
  color: black;
  padding: 15px 70px;
  max-width: 300px;
  background-color: rgba(255, 255, 255, 0.8);
  /* margin-bottom: 25px !important; */
  border: 2px solid black;
  /* margin: auto; */
  margin-bottom: 30px !important;
  font-weight: 900;
  border-radius: 200px;
}

.btn-h:hover {
  color: white;
  background-color: black;
  transition: 0.5s;
  border: 2px solid #1b1b1b;
}
/*btn_background*/
.effect04 {
  --uismLinkDisplay: var(--smLinkDisplay, inline-flex);
  display: var(--uismLinkDisplay);
  color: black;
  /* outline: solid 2px #000; */
  position: relative;
  transition-duration: 0.4s;
  overflow: hidden;
}
.effect04::before,
.effect04 span {
  margin: 0 auto;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-duration: 0.4s;
}
.effect04:hover {
  background-color: black;
}
.effect04:hover span {
  -webkit-transform: translateY(-400%) scale(-0.1, 20);
  transform: translateY(-400%) scale(-0.1, 20);
}
.effect04::before {
  content: attr(data-sm-link-text);
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translateY(500%) scale(-0.1, 20);
  transform: translateY(500%) scale(-0.1, 20);
}
.effect04:hover::before {
  letter-spacing: 0.05em;
  -webkit-transform: translateY(0) scale(1, 1);
  transform: translateY(0) scale(1, 1);
}
.botones-secundario {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: .4em 0em;
}

.modal-button{
  background-color: #103a87;
  color: white;
  font-weight: 900;
  font-size: 1.3rem;
  width: 300px !important;
  transition: .3s;
  margin: 10px auto;
}
.modal-button.custom{
  width: 380px !important;
}
.modal-button:hover span{
  transition: all .3s !important;
  margin: 0 auto;
}
.modal-button:hover{
  cursor: pointer;
  background-color: #042562;
  color: white;
  /* font-size: 1.35rem; */
  transform: scale(1.05);
  transition: .3s;
}
/* /////////////////////////////////////////////////////////////////////////////// */

.popover-soy-nuevo,
.popover-soy-inst,
.popover-primero {
  cursor: default;
  text-decoration: none;
  border-radius: 100px;
  background-color: #b60a0a;
  font-size: 15px;
  padding: 5px 10px !important;
  position: relative;
  margin-left: 5px;
  width: 50px;
  color: white;
  font-weight: 900;
  text-align: center;
}
.popover-soy-nuevo:hover,
.popover-soy-inst:hover,
.popover-primero:hover {
  text-decoration: none;
  background-color: #6e0a0a;
}
