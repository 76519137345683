.container {
  /* min-height: auto; */
  margin-top: 40px;
  margin-bottom: 40px;
}

.fondo {
  /* content: "";
  position: relative; */
  margin-left: 20px;
}
.foto-william {
  background: url("../img/williamMorris.jpg");
  background-size: cover;
  height: 50vh;
}

.foto-chica {
  background: url("../img/chicalibreria.jpg");
  background-size: cover;
  height: 75vh;
}
.titulo-inst {
  font-family: "B612", sans-serif;
  margin-left: 20px;
  /* font-weight: bold; */
}
.contenido-texto {
  margin-left: 20px;
}

@media screen and (max-width: 769px) {
  .contenido-texto {
    font-size: 13px;
  }
}
