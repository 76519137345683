.fondo {
  position: relative;
  width: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  height: 25vh;
  background-image: url("../img/jardin2.jpg");
  background-position-y: center;
  background-size: cover;
}
.titulo-separador {
  text-align: center;
}
/* //////////////////////////////////////////////////// */
.col-para {
  position: relative;
}
.imagen {
  position: absolute;
  background-position-y: bottom;
  background-size: cover;
  width: 100%;
  height: 40vh;
}

/* 871  645  541 */
@media screen and (max-width: 541px) {
  .insfoto {
    /* height: 50vh !important;
    width: 50vh; */
    background-size: cover !important;
    background: url("../img/inscripcionabiertacel.png") !important;
  }
}
.insfoto {
  position: absolute;
  background-size: cover !important;
  background-position-y: center !important;
  background-position-x: center !important;
  /* bottom: 100px; */
  height: 100%;
  width: 100%;
  background: url("../img/inscripcionabierta1.png");
}
.rectangulo {
  font-family: piola;
  position: relative;
  width: 100%;
  height: 80vh;
  background: #ffffff;
  margin-top: 100px;
  border-radius: 0 95px 0 0;
}
/* ojo */
.col-sm-12 {
  padding-right: 0px !important;
}
.rectangulo h3 {
  position: absolute;
  color: rgb(251, 197, 191);
  font-size: 5.5vw;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
}
.info-card-text {
  color: white;
  font-family: sans-serif;
  position: absolute;
  font-size: 1.3vw;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 14vw;
}
.circulo-click-aqui {
  display: none;
}
.circulo {
  width: 15vh;
  height: 5vh;
  border-radius: 10px;
  background: purple;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-weight: bold !important;
  transition-property: color;
  transition-duration: 2.4s;
  /* transition: background 0.4s ease-in-out; */
  position: relative;
  margin: 356px auto 96px auto;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: visible;
}
.circulo h4 {
  color: white;
  font-size: 16px;
}
.circulo h4 i {
  font-weight: 40px !important;
  font-size: 16px;
}

.circulo:hover {
  background: red;
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .circulo {
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background: purple;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-weight: bold !important;
    transition-property: color;
    transition-duration: 2.4s;
    /* transition: background 0.4s ease-in-out; */
    position: relative;
    margin: 25vw auto 5vw auto;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    overflow: visible;
  }
  .circulo-click-aqui {
    display: none;
  }
  .circulo h4 {
    font-size: 14px;
  }
  .rectangulo h3 {
    font-size: 8vw;
    /* margin: 50px; */
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
  }
  .info-card-text {
    font-size: 2.3vw;
    margin-left: 20px;
    margin-top: 40px;
  }
  .rectangulo {
    margin-top: 190px;
    height: 50vh;
    padding-bottom: 430px;
  }
  .imagen {
    height: 250px;
    background-position-y: center;
  }
}
@media screen and (max-width: 630px) {
  .rectangulo {
    height: 40vh;
  }
}

@media screen and (min-width: 1877px) {
  .rectangulo h3 {
    font-size: 4vw;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .info-card-text {
    font-size: 1.3vw;
    margin-left: 20px;
    margin-top: -80px;
  }
}
