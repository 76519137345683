.icono {
  text-align: center;
  padding: 20px;
}

.contenido {
  text-align: center;
}
.contenido h3 {
  font-size: 25px;
  color: rgb(255, 231, 17);
  font-weight: bold;
  background: rgba(10, 10, 10, 0.856);
}
.pancarta {
  color: white;
  background: black;
  padding: 0px;
  margin: 0px;
  border-radius: 0 95px 0 0;
  padding-bottom: -130px !important;
}
.pancarta h1 {
  padding-top: 30px;
  font-weight: bold;
  font-size: 47px;
}

.pancarta h1::after {
  background: #9a1010;
  content: "";
  display: block;
  height: 10px;
  margin: 25px 0 45px;
  width: 80px;
}

.pancarta p {
  text-align: left;
  margin-top: -15px;
  margin-bottom: 30px;
  /* font-weight: bold; */
}

.text-pancarta {
  margin-left: 20px;
}

@media (min-width: 1200px) {
  .text-pancarta {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    margin-left: 50px;
  }
}

.image-pancarta {
  position: relative;
  width: 100%;
  height: auto;
  background: url("../img/FotoPublicidad.JPG");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 769px) {
  .image-pancarta {
    height: 500px;
  }
  .text-pancarta h1 {
    font-size: 30px;
  }
}

.bloque-negro {
  position: absolute;
  width: 100%;
  height: 200px;
  background: black;
}
