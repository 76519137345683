.envianos-un-mensaje {
  /* font-family: "B612", sans-serif; */
  font-family: piola;
  text-align: left;
  /* color: #c72914; */
  font-size: 100px;
  line-height: 1;
  margin-bottom: 30px;
  font-weight: 600;
}
#c1 {
  color: #c72914;
}
#c2 {
  color: #1e2337;
}
#c3 {
  color: tomato;
}
#c4 {
  color: sandybrown;
}
#c5 {
  color: #543dd4;
}
.envianos-un-mensaje-hr {
  width: 10%;
  border-bottom: 10px solid;
  margin: 0;
}
.contenedor-contact-niveles {
  margin-top: 100px !important;
}
.contenedor-contact-niveles p {
  /* color: #d16254; */
  font-weight: bold;
  font-size: 20px;
}
.area-mensaje {
  max-height: 300px !important;
  min-height: 147px !important;
  margin-left: 10px;
}
.inputs {
  padding-left: 0px !important;
}
.mensaje-form {
  padding-left: 0px !important;
}
.bt-ajust {
  background: none !important;
}
@media screen and (max-width: 769px) {
  .area-mensaje {
    margin-left: 0px;
  }
}
@media screen and (max-width: 600px) {
  .contenedor-contact-niveles {
    padding-left: 40px;
    padding-right: 0px;
    margin-right: 0px;
  }
  .envianos-un-mensaje {
    font-size: 50px;
  }
  .area-mensaje {
    margin-right: 30px !important;
  }
  .contenedor-contact-niveles p {
    font-size: 13px;
  }
}
@media screen and (max-width: 350px) {
  .bt-1 {
    font-size: 14px !important;
  }
  .contenedor-contact-niveles p {
    font-size: 13px;
  }
}

/* OJO */
.row > .col-12 {
  padding-left: 0px !important;
}
