.salitas-subtitulo {
  font-family: piola;
  font-size: 50px;
  /* color: tomato; */
  font-weight: bold;
}
.NocCol {
  color: tomato;
}
.salitas-titulo {
  font-family: piola;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}

.salitas .sala {
  font-weight: bold;
  font-size: 1rem;
}
.salitas .sala-color {
  font-size: 1rem;
}

.cuadrito h2 {
  font-family: piola;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 55px;
  padding-top: 8px;
}

.salitas h1 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}

@media screen and (max-width: 600px) {
  .salas-conocimiento {
    padding-left: 40px;
  }
}

/* ////////////////////////////// */
.divisor-info {
  position: relative;
  background: rgb(255, 168, 158);
  width: 100%;
  height: auto !important;
  margin-top: 320px;
}
.nenefoto {
  position: absolute;
  height: 400px;
  width: 400px;
  top: -351px;
  z-index: 100;
}

.divisor-info p {
  color: rgb(255, 100, 82);
  padding: 0 30px;
  padding-bottom: 60px;
  padding-right: 230px;
  font-family: piola;
  font-weight: bold;
}
.divisor-info h3 {
  font-family: piola;
  color: rgb(253, 67, 46);
  padding: 0 30px;
  padding-top: 60px;
  font-weight: bold;
}

/* Altos problemas este slider NO USAR */

#ReactBackgroundSlider > figure {
  z-index: 1 !important;
}
@media screen and (max-width: 769px) {
  #ReactBackgroundSlider > figure {
    position: absolute !important;
    display: auto !important;
    height: 300px !important;
  }
  .divisor-info h3 {
    padding-top: 340px;
  }
  .cuadrito h2 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 972px) {
  .divisor-info {
    margin-top: 340px !important;
  }
  .divisor-info p {
    padding-right: 0px;
  }
}

@media screen and (min-width: 1150px) {
  .contenido-tarjeta {
    padding-right: 200px;
  }
}
.row1 {
  margin-top: 70px;
}

.imageFloat1 {
  width: 200px;
  height: 200px;
  margin-top: 70px;
  margin-left: 70px;
  position: absolute;
  top: 140px;
}
.imageFloat2 {
  width: 100px;
  height: 220px;
  position: absolute;
  top: 300px;
}

@media screen and (max-width: 1000px) {
  .imageFloat1,
  .imageFloat2 {
    display: none;
  }
}

.posicionamientoboton {
  /* margin-top: 0px; */
}
/* //////////////////////////////////// */
