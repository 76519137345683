.bt-class {
  text-align: center;
}
Form Control{
  font-size: 3.3rem !important;
}

.row-mensaje {
  padding-left: 100px;
  text-align: left !important;
}
.envianos-mensaje {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.mensaje {
  text-align: left;
  /* padding-left: 38px; */
}

@media screen and (max-width: 500px) {
  .row-mensaje {
    padding-left: 30px;
  }
  .contenedor-contact-niveles p {
    font-size: 14px;
  }
}

/* Botonnn */
.bt-1 {
  border-color: #000;
  color: #000;
  margin-top: 20px;
  padding: 0 95px;
  font-weight: 900;
  border: 2px solid;
  border-radius: 30px;
  display: inline-block;
  font-size: 22px;
  line-height: 56px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.bt-1:hover {
  color: white;
  background: #000 !important;
  cursor: pointer;
  text-decoration: none;
}

.bt-1-white {
  color: rgb(255, 255, 255);
  background-color: #103a87;
  margin-top: 20px;
  padding: 0px 50px;
  /* border: 3px solid; */
  border-radius: 30px;
  display: inline-block;
  line-height: 56px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  font-weight: 900 !important;
  font-size: 1.5rem;
}

.bt-1-white:hover {
  color: #103a87;
  background: white !important;
  cursor: pointer;
  text-decoration: none;
  font-size: bold;
}

@media screen and (max-width: 555px) {
  .bt-1-white {
    font-size: 10px;
    padding: 0px 25px;
  }
  .bt-1-white-correccion {
    bottom: 10px !important;
  }
}
