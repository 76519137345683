.dropdown-menu {
  /* display: contents; */
  display: flex;
  background: none;
  width: 200px;
  height: 230px;
  left: auto;
  right: 260px;
  z-index: 1000;
  top: auto;
  flex-direction: column;
  border: none;
  /* position: absolute;
  list-style: none;
  text-align: start; */
}

.dropdown-menu li {
  background-color: rgba(17, 16, 16, 0.8);
  transition: all 0.3s ease-out !important;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.dropdown-menu.clicked {
  display: none;
}
.dropdown-inicial {
  margin-top: -5px;
}

.dropdown-link {
  display: flex;

  height: 100%;
  width: 100%;
  text-decoration: none !important;
  color: #fff !important;
  padding: 16px;
}

@media screen and (max-width: 930px) {
  .fa-caret-down {
    display: none;
  }
}
