.content-cards .row .col {
  flex-grow: unset !important;
}
.content-cards .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cards-rh .col > a {
  text-decoration: none !important;
  color: auto !important;
}

.card-rrhh {
  position: relative;
  margin: 10px auto;
  width: 300px;
  height: 400px;
  border-radius: 5px;
  transition: all 0.4s ease-in-out !important;
}

.card-rrhh > h2 {
  font-family: piola;
  text-align: center;
  padding-top: 80px;
}
.c1 {
  color: #b60a0a;
}
.c2 {
  color: rgb(255, 99, 60);
}
.c3 {
  color: rgb(238, 253, 101);
}
.c4 {
  color: #1e2337;
}
.card-rrhh > h4 {
  font-family: "B612", sans-serif;
  position: absolute;
  text-align: center;
  top: 70%;
  right: 30%;
  color: black;
}
.card-rrhh:hover {
  filter: brightness(90%);
}

.col:nth-child(1) .card-rrhh {
  background-color: indianred;
}
.col:nth-child(2) .card-rrhh {
  background-color: khaki;
}
.col:nth-child(3) .card-rrhh {
  background-color: lightskyblue;
}

.icon-mid {
  position: absolute;
  top: 50%;
  right: 45%;
  text-align: center;
  font-weight: 40px !important;
  font-size: 40px;
  color: aliceblue;
}

.arrow-icon {
  position: absolute;
  top: 75%;
  right: 45%;
  text-align: center;
  font-weight: 40px !important;
  font-size: 40px;
  color: black;
}

.mar-no {
  margin-top: -30px !important;
}
