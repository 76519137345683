.separador-image-1 {
  /* background: url("../img/jardin2.jpg"); */
  background-position: center center;
  /* background-size: cover; */
  margin-top: 40px;
  margin-bottom: 80px;
  width: auto;
  height: 150px;
  /* border: solid 2px black; */
  filter: brightness(0.7);
  /* border: solid 2px black; */
}
