.cont-encabezado {
  position: relative;
  height: 60vw;
  /* border: 2px solid black; */
  margin-bottom: 0px;
  top: -90px;
}
.encabezadoInicio {
  background: url("../img/Cartelinicio.jpg");
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center top;
  z-index: -2;
  /* border: 2px solid red; */
}
.wave {
  position: absolute;
  /* margin-top: -135px; */
  width: 100%;
  /* height: 200px; */
  z-index: -1;
  color: white;
  bottom: -130px;
  /* border: 2px solid blue; */
}
.clickscroll {
  position: relative;
  width: 100%;
  text-align: center;
  align-items: center;
}
.clickscroll .default {
  /* position: absolute; */
  cursor: pointer;
  z-index: 10;
  bottom: 20vw;
  /* right: 43.2vw; */
  margin: 0 auto !important;
  border-radius: 50px;
  font-family: piola;
  color: black;
  background: white;
  text-decoration: none;
  /* padding: 0.5rem 3rem; */
}
.clickscroll .default:hover {
  background-color: rgba(17, 16, 16, 0.8);
  color: white;
  border-radius: 50px !important;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease-out !important;
}
@media screen and (max-width: 1250px) {
  .wave {
    bottom: -60px;
  }
}
@media screen and (max-width: 700px) {
  .encabezadoInicio {
    background: url("../img/CartelinicioCel.jpg");
    position: relative;
    margin-top: -20vw;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center top;
    z-index: -2;
  }
  .wave {
    bottom: -30px !important;
  }
  .cont-encabezado {
    height: 200vw;
  }
  .clickscroll .default {
    bottom: 80.5vw !important;
    right: 1.2vw !important;
  }
}
.botonClickAqui{
  padding: 5px 30px !important;
  color: #103a87 !important;
  position: relative;
  /* left: 600px; */
  text-align: center;
  font-size: 2.5rem;
}