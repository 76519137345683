.card-modal {
  font-weight: 900;
}
.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}
.text-wp {
  position: fixed;
  color: white;
  text-align: center;
  font-size: 20px;
  z-index: 90;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #25d366;
  margin-top: 15px;
  margin-right: 10px;
  right: 100px;
  animation-name: sevaboton;
  animation-duration: 5s;
}
.whatsapp:hover {
  color: white;
}
.whatsapp-icon {
  margin-top: 13px;
}
.display-none {
  display: none;
  right: -200px;
}

.parallax {
  min-height: 400px;
  background: transparent;
}
/* FOTOS DE FONDO */
.fondo-inicio::before {
  content: "";
  position: absolute;
  /* margin: -6px; */
  top: 0;
  left: 0;
  width: 100%;
  height: 770px;
  border-radius: 0 0 50% 50%/0 0 50% 50%;
  transform: scaleX(1.5);
  background: url("../img/bible.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.8) !important ;
  filter: brightness(0.6) !important;
}

/* //////////////////////////////////////////////////// */
.fondo-1 {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 0 0 50% 50%/0 0 50% 50%; */
  /* transform: scaleX(1.5); */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(10, 10, 10, 0.5)),
    url("../img/pexels-pixabay-207697.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* 3024 x 4032 */
.separador {
  width: 100%;
  overflow: hidden;
}

.separador-image-1 {
  background: url("../img/ninosfelices.jpg");
  background-position: bottom bottom;
  background-size: cover;
  margin-top: 40px;
  margin-bottom: 80px;
  width: auto;
  height: 150px;
  filter: brightness(0.7);
}

.separador-image-2 {
  /* background: url("../img/ninos1.jpg"); */
  background-position: center center;
  /* background-size: cover; */
  margin-top: 40px;
  margin-bottom: 80px;
  width: auto;
  height: 150px;
  filter: brightness(0.7);
}
.separador-image-3 {
  /* background: url("../img/study.jpg"); */
  background-position: center center;
  background-size: cover;
  margin-top: 40px;
  margin-bottom: 80px;
  width: auto;
  height: 150px;
  filter: brightness(0.7);
}
.separador-image-4 {
  /* background: url("../img/fondo-info-3.jpg"); */
  /* background-position: bottom bottom; */
  /* background-size: cover; */
  margin-top: 40px;
  margin-bottom: 40px;
  width: auto;
  height: 190px;
  filter: brightness(0.7);
}

/* ///////////////////////////////////////////// SECUNDARIO ///////////////////////////////////////////////// */
.anos-secundario {
  text-align: center;
  box-sizing: border-box;
}
.campo-aprendizaje-secundario h1 {
  text-align: left;
  font-weight: 900;
  font-size: 100px;
  color: #a49452;
}
@media screen and (max-width: 600px) {
  .campo-aprendizaje-secundario h1 {
    font-size: 50px;
  }
  .posicionamientoboton .mequieroinscribirBTN {
    font-size: 20px;
    padding: 10px;
  }
  .popover-soy-nuevo,
  .popover-soy-inst,
  .popover-primero {
    display: none;
  }
  .modal-header h3 {
    font-size: 20px !important;
  }
  .separador-image-2,
  .separador-image-3,
  .separador-image-4 {
    width: 200%;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.card-materias {
  color: black;
  text-align: center;
  border-radius: 10px 10px;
}
.card-materias li {
  list-style: none;
  margin-top: 15px;
}
.card-materias h2 {
  padding-top: 30px;
}
.modal-body {
  width: auto;
  padding: 0px;
  margin: 0px;
  position: relative;
  /* top: 10px; */
  border-radius: 0px;
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  /* background-image: url(../../src/img/wave1.png); */
  width: 100%;
}
.modal-dialog{
  display: flex !important;
  align-items: center !important;
  /* height: 100vh; */
  /* overflow-y: scroll; */
  min-width: 600px;
 
}
.modal-general {
  background-color: rgba(0, 0, 0, 0.7);
  
  display: flex !important;
}
.modal-title{
  font-weight: 900;
}
hr{
  width: 25%;
}
.modal-text{
  font-size: 1.5rem !important;
}
.modal-body h2 {
  text-align: center;
}
.modal-content {
  padding: 20px;
  margin: 0px;
  max-height: 90vh;
  min-width: 400px;
  overflow-y: auto;
}
.modal-body .card-modal .boton-centrado {
  text-align: center;
}
.modal-header {
  display: none;
  height: 50px;
  color: black;
  text-align: center;
}
.modal-header h3 {
  font-size: 25px;
  text-align: center;
  font-weight: 900;
  justify-self: center;
  margin-left: 20px;
}
.border-bottom-green {
  position: relative;
}
.border-bottom-green:after {
  content: "";
  display: block;
  width: 0%;
  height: 5px;
  background: #fc9f25;
  position: absolute;
  bottom: -20%;
  border-radius: 10px;
}
.border-bottom-green:after {
  width: 100%;
  left: 0%;
  animation: slideborder 0.5s;
}
@keyframes slideborder {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.footer-modal {
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(224, 43, 255, 1) 0%,
    rgba(168, 118, 255, 1) 100%
  );
  height: 50px;
}
.footer-modal-cerrar .footer-modal {
  text-decoration: none;
  color: white;
  font-weight: 900;
  font-size: 30px;
  cursor: pointer;
}
.footer-modal-cerrar:hover {
  text-decoration: none;
}
.fas,
.pointer-cursor {
  cursor: pointer;
}
.posicionamientoboton {
  text-align: center;
}
.posicionamientoboton2 {
  text-align: center;
}
@media screen and (max-width: 500px) {
  .bt-1-xs {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* /////////////////////////////////////////////  */
/* Carteles inscripcion */
.cartel-ins {
  position: relative;
  margin-top: 40px;
  margin-bottom: 754px;
}
.arrowBoton {
  position: absolute;
  bottom: 5px;
  right: 46%;
  z-index: 10;
}
/* BOTON DE ADENTRO DE LAS MODALES */
.bt-1-xs {
  position: relative;
  background-color: rgb(255, 255, 255);
  color: black;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: bold;
  padding: 0px 10px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 30px;
  display: inline-block;
  line-height: 56px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.bt-1-xs:hover {
  font-weight: bold !important;
  color: white;
  background: rgb(255, 161, 21) !important;
  cursor: pointer;
  text-decoration: none;
}
.bt-add {
  font-size: 20px;
  padding: 0px 40px !important;
}

.text-ins {
  font-family: "B612", sans-serif;
  position: absolute;
  top: 140px;
  left: -190px;
}
.text-ins h1 {
  font-weight: bold;
}

.bot {
  position: relative;
}
.bot-1 {
  position: absolute;
  top: 30px;
  left: -190px;
  /* right: 40%; */
  z-index: 1000;
}

.fondo-cartel {
  position: absolute;
  background: rgb(246, 115, 92);
  padding: 190px 0px;
  width: 600px;
  right: 10%;
}
.rigt {
  background: rgb(255, 168, 86);
}
.color {
  background: rgb(248, 93, 93);
}
.color-noct {
  background: rgb(255, 219, 151);
}

.imageFloat4 {
  position: absolute;
  top: 100px;
  left: 140px;
  width: 460px;
  height: 400px;
}
.ajuste {
  width: 330px;
  height: 530px;
}
.ajust {
  top: 150px;
  width: 430px;
  height: 330px;
}

@media screen and (max-width: 892px) {
  .cartel-ins {
    margin-bottom: 880px;
  }
  .text-ins {
    left: 20px;
    top: -0px;
  }
  .bot-1 {
    padding-top: 20px;
    left: 20px;
    top: -140px;
  }
}
@media screen and (max-width: 700px) {
  .text-ins {
    left: 335px;
    top: 60px;
  }
  .text-ins h1 {
    font-size: 23px;
  }

  .modal-dialog{
    max-width: 95vw;
  }
  
  .modal-body{
    max-width: 95vw;
  }

  .bot-1 {
    padding-top: 0px;
    left: 330px;
    top: -80px;
  }
  .bot-1 a {
    font-size: 14px;
  }
  .imageFloat4 {
    top: 200px;
    left: 240px;
    width: 360px;
    height: 300px;
  }
  .ajuste {
    width: 230px;
    height: 390px;
  }
}
.modal-content {
  border-radius: 7px;
}

/* //////////////////////////// */

.rect-fondo {
  position: relative;
  margin: 0px 300px;
}
.rect-fondo h1 {
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: 34px;
}

.fondo-rec {
  /* position: absolute; */
  width: 100%;
}
#bocadillo {
  padding: 10px;
  z-index: 1;
  width: 220px;
  border-radius: 10px;
  width: 90%;
  text-align: center;
  position: relative;
  /* left: 50px; */
  background-color: white;
  border: black 1px solid;
  font-family: "trebuchet ms", tahoma, verdana, sans­serif;
  font-size: 1.4em;
}
.imagen-bocadillo{
  position: relative;
  margin-left: -10px;
}
.contenedorBocadillo{
  display: flex;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 30px;
}
.hr50{
  width: 30%;
  font-weight: 900;
}
.postalPublicitario{
  height: 550px;
  background-position-x: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-position: bottom; */
  /* background-image: url(../../src/img/postsecundario.jpg); */
}
@media screen and (max-width: 700px){
  .postalPublicitario{
    height: 550px;
    background-position-x: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-image: url(../../src/img/postsecundariocelu.jpg); */
  }
}

.img-promo{
  background-image: url(../../src/img/promo.jpeg);
  height: 350px;
  width: 100%;
  background-size: cover;
  object-fit: contain !important;
  margin: 20px 0px;
}

.img-promo.noc{
  /* height: 400px; */
  background-image: url(../../src/img/secnocturno2023.jpg) !important;
}

/* Lottie */
.lottie-custom{
  /* position: absolute !important; */
  /* top: 0; */
  /* margin-top: -300px !important; */
  margin-right: 20px;
  z-index: 10000 !important;
}
Lottie{
  position: relative;
  z-index: 10000 !important;
}