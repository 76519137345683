.capa-gris {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  flex: 1;
}

.cabeza {
  position: relative;
  width: 100%;
  height: 780px;
}
.cabezaSimple {
  position: relative;
  margin-bottom: 0px;
  margin-top: -10px;
  top: 10px;
  width: 100%;
  height: 85px;
  background-color: #0e3886;
  z-index: -3;
  /* background: linear-gradient(to bottom, #284988, white); */
}

.onda {
  position: absolute;
  margin-top: -135px;
  height: 150px;
  width: 100%;
  z-index: 100;
}
.parallax {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(10, 10, 10, 0.5)),
    url("../img/pexels-pixabay-207697.jpg");
  height: 700px;
  width: 100%;
  min-height: 600px;
  background: transparent;
}

/* ///////////////////////////////////////// */
.niveles-encabezado {
  position: relative;
  width: 100% !important;
  height: 790px;
}
.img-encabezado {
  position: absolute;
  background-size: cover;
  height: 120%;
  width: 100%;
  top: 100px;
}

.niveles-encabezado-titulo {
  position: absolute;
  width: 100%;
  color: #fff;
  text-align: center;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  padding-left: 50px;
  padding-right: 50px;
}

.niveles-encabezado-titulo h1 {
  /* font-family: "B612", sans-serif !important; */
  /* font-family: piola !important; */
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: "Montserrat", Sans-serif, bold;
  font-weight: bold;
  letter-spacing: 3px;
}

.niveles-encabezado-titulo h4 {
  color: rgb(255, 231, 17);
  font-weight: bold;
  font-size: 33px;
}
.niveles-encabezado-titulo h2 {
  color: rgb(255, 231, 17);
  margin-top: 10px;
  text-align: right;
}

.niveles-encabezado-titulo {
  text-align: center;
}

/* ver titulos para cel */
@media screen and (max-width: 900px) {
  .cabezaSimple {
    height: 100px;
  }
  .niveles-encabezado-titulo h1 {
    font-size: 90% !important;
  }
  .niveles-encabezado-titulo h2 {
    font-size: 98% !important;
  }
  .niveles-encabezado-titulo h4 {
    font-size: 100% !important;
  }
}

.verde {
  background: rgb(52, 65, 26);
}
.gris {
  background: #0e3886;
}
.black {
  background: linear-gradient(0deg, white, #0e3886 80%);
}
