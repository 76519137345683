* body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}
@font-face {
  font-family: piola;
  src: url("./Retroica.ttf");
}
p, span, label, input{
  font-size: 1.3rem;
}
.inicio,
.niveles,
.RRHH,
.contacto,
.iniciarsesion,
.primario,
.secundario,
.nocturno {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* Clases de contacto */
.icon-write {
  position: relative;
  top: -106px;
}
.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  max-width: 680px;
}

.btn-lg:hover {
  background-color: #205481;
  color: #fff;
}

.row {
  margin-left: 0 !important;
  /* margin-right: 0 !important;  */
}
.lottie-custom{
  /* position: absolute !important; */
  /* top: 0; */
  /* margin-top: -300px !important; */
  margin-right: 20px;
  z-index: 10000 !important;
  position: relative;
}
svg{
  position: relative;
  z-index: 10000 !important;
}
.error {
  font-size: 70px;
  margin-top: 300px;
  margin-bottom: 30px;
}
